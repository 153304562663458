.title_page {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    color: #005765;
}

.online {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
}

.list_conversation {
    height: 81vh;
    overflow: hidden;
    overflow-y: scroll;
}

.selected_person {
    position: relative;
    height: 84.5vh;
}

.prepare_send_img {
    position: absolute;
    height: 84.5vh;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 2;
}

.prepare_vcall {
    display: flex;
    position: absolute;
    height: 100vh;
    top: 0;
    width: 70%;
    padding: 10px;
    background: #18191a;
    z-index: 2;
}



.ur_video {
    width: 60%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px !important;
    background: #005765;
}

.control_vcall {
    flex: 1;
    padding-left: 10px;
}

.my_video {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border-radius: 8px !important;
}

.input_hide {
    display: none;
}

.frame_img {
    height: 300px;
    box-shadow: 10px 10px 23px -1px rgba(0, 0, 0, 0.22);
    -webkit-box-shadow: 10px 10px 23px -1px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 10px 10px 23px -1px rgba(0, 0, 0, 0.22);
}

.person {
    height: 8vh;
    background: #10A3B0;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pre_format {
    /* word-break: break-all; */
    white-space: pre-wrap;
}

.timer {
    height: 6vh;
    background: #F1FAFB;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.running_time {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    color: #00786A;
}

.temp_chat {
    height: 60.5vh;
    background: #fff;
    overflow: hidden;
    overflow-y: scroll;
    padding: 10px 5px;
}

.toolbar {
    border-top: 2px solid #8AC7CF;
    min-height: 10vh;
    width: 100%;
    background: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.container_reply {
    width: 100%;
    background: #fff;
}

.reply_txt {
    padding: 0 10px;
    flex: 1;
}

.list_conversation::-webkit-scrollbar {
    width: 5px !important;
}

/* Track */
.list_conversation::-webkit-scrollbar-track {
    background: #fff !important;
    /* border-radius: 10px !important; */
}

/* Handle */
.list_conversation::-webkit-scrollbar-thumb {
    background: #888 !important;
    /* border-radius: 10px !important; */
}

/* Handle on hover */
.list_conversation::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.item_conversation {
    cursor: pointer;
    background: #fff;
}

.item_conversation:hover {
    background: #dddd;
}

.temp_chat::-webkit-scrollbar {
    width: 5px !important;
}

/* Track */
.temp_chat::-webkit-scrollbar-track {
    background: #fff !important;
    /* border-radius: 10px !important; */
}

/* Handle */
.temp_chat::-webkit-scrollbar-thumb {
    background: #888 !important;
    /* border-radius: 10px !important; */
}

/* Handle on hover */
.temp_chat::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.my_temp {
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transition: 0.5s;
    border-radius: 5px;
}

.my_half {
    display: flex;
    justify-content: flex-end;
    width: 70%;
}

.my_ballon {
    position: relative;
    background: #007585;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 5px;
    border-radius: 10px 10px 0 10px;
    box-shadow: 2px 3px 44px -32px rgba(0, 0, 0, 0.75);
    transition: 2s all !important;
    min-width: 100px;
}

.prereply_img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.image_in_msg {
    width: 250px;
    height: 250px;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
}

.ur_reply {
    padding: 5px;
    border-radius: 5px;
    background: rgb(222, 222, 222);
    cursor: pointer;
}

.your_temp {
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    transition: 0.5s;
    border-radius: 5px;
}

.your_half {
    display: flex;
    justify-content: flex-start;
    width: 70%;
}

.your_ballon {
    position: relative;
    background: #E6EEEF;
    padding: 5px;
    font-weight: 500;
    color: #000;
    font-size: 14px;
    border-radius: 0 10px 10px 10px;
    box-shadow: 2px 3px 44px -32px rgba(0, 0, 0, 0.75);
    transition: 2s all !important;
    min-width: 100px;

}

.clock {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: right;
    color: #77B7BF;
}

.temp_recorder {
    height: 40px;
    width: 300px;
    border-radius: 20px;
    background: #cac9c9;
    overflow: hidden;
    position: relative;
}

.recorded {
    height: 100%;
    background: #89bcc4;
    transition: all .5s;
    position: absolute;
    top: 0;
    left: 0;
}

.dp_rcd {
    position: relative;
    z-index: 5;
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.overlay_call {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: #18191a;
    z-index: 5;
    top: 0;
    left: 0;
}