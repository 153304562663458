.dtbl_input {
    width: 100%;
    padding: 10px;
    /* border-radius: 8px; */
    border: none;
    box-sizing: border-box;
    flex: 1;
}

.dtbl_input:focus {
    outline: none;
}

.icon_input {
    width: 100%;
    padding: 10px;
    border: none;
    box-sizing: border-box;
    flex: 1;
}

.icon_input:focus {
    outline: none;
    background: #fff !important;
}

.select_custom {
    width: 100%;
    border: none;
    box-sizing: border-box;
    flex: 1;
}

.select_custom:focus {
    outline: none !important;
    background: #fff !important;
}


.opt {
    border: none;
}

.icon_input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.icon_input {
    -moz-appearance: textfield;
    /* Firefox */
}

.input_psy {
    width: 100%;
    padding: 10px;
    border: none;
    box-sizing: border-box;
    flex: 1;
}