.unpick {
  padding: 5px 10px;
  border: 1px solid #8ac7cf;
  border-radius: 3px;
  /* width: max-content; */
  cursor: pointer;
  transition: 0.5s;
  font-weight: 500;
  text-align: center;
}

.unpick:hover {
  background: #d8ebed;
  transition: 0.5s;
}

.unpickDisable {
  padding: 5px 10px;
  border: 1px solid #a2a2a2;
  border-radius: 3px;
  /* width: max-content; */
  cursor: pointer;
  transition: 0.5s;
  font-weight: 500;
  text-align: center;
}

.pick {
  padding: 5px 10px;
  border: 1px solid #8ac7cf;
  border-radius: 3px;
  text-align: center;
  /* width: max-content; */
  cursor: pointer;
  transition: 0.5s;
  font-weight: 500;
}
