.bg_side {
  border-radius: 8px;
  padding: 8px;
  min-height: 200px;
}

.card_company {
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.logo_tb {
  height: 40px;
}

.item_unactive {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 8px;
}

.item_unactive:hover {
  background: #fafafa31;
}

.item_active {
  padding: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}

.expandable {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.expandable:hover {
  background: #fafafa31;
}

.expandable_2 {
  padding: 10px;
  border-radius: 8px;
}

.expandable_2:hover {
  background: #fafafa31;
}

.item_unactive_2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 8px;
}

.item_unactive_2:hover {
  background: #fafafa31;
}

.item_active_2 {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}
