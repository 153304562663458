@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
}


/* GENERAL */

* {
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid var(--border-color);
    background: var(--neutral-color);
}

header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
}

header #logo .icon {
    padding-right: .25em;
}

main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
}


/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.space {
    width: 50px;
}

.col-start {
    justify-content: flex-start;
    text-align: left;
}

.col-center {
    justify-content: center;
    text-align: center;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}


/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
}

.calendar .header .icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    color: var(--main-color);
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid #F6E5FE;
}

.calendar .body .cell {
    position: relative;
    /* min-height: 5em; */
    height: 140px;
    border-right: 1px solid #F6E5FE;
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
}

.calendar .body .selected {
    border-left: 10px solid transparent;
    background: #F6E5FE;
}

.calendar .body .row {
    border-bottom: 1px solid #F6E5FE;
}

.calendar .body .row:last-child {
    border-bottom: none;
}

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    display: flex;
    justify-content: flex-end;
    font-size: 82.5%;
    line-height: 1;
    padding: .75em;
    font-weight: 700;
}

.calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
}

.calendar .body .on_month {
    color: #3D0051;
}

.card_days {
    background: #6D057E;
    border-radius: 10px 10px 0 0;
}

.calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
    opacity: 0.05;
    transition: .5s ease-in;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
}

.pill-time {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    font-weight: 500;
    color: #fff;
    background: #3D0051;
    font-size: small;
}