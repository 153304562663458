/* lain lain */
.max-content {
  width: max-content !important;
}

.full_width {
  width: 100%;
}

.max-content-h {
  height: max-content !important;
}

/* border width */
.border {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

/* Border Color */
.border-primary {
  border-color: #000 !important;
}

.border-slate-100 {
  border-color: rgb(241 245 249) !important;
}

/* border radius */

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-lg {
  border-radius: 8px !important;
}

.rounded-top-lg {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.rounded-bottom-lg {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.rounded-xl {
  border-radius: 24px !important;
}

.pill {
  border-radius: 30px !important;
}

.borbox {
  box-sizing: border-box !important;
}

/* padding */

/* padding all */
.pa-0 {
  padding: 0 !important;
}

.pa-1 {
  padding: 4px !important;
}

.pa-1_5 {
  padding: 6px !important;
}

.pa-2 {
  padding: 8px !important;
}

.pa-3 {
  padding: 12px !important;
}

.pa-4 {
  padding: 16px !important;
}

.pa-5 {
  padding: 20px !important;
}

.pa-50 {
  padding: 50px !important;
}

.pa-100 {
  padding: 100px !important;
}

/* padding x */
.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

/* padding y */
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

/* padding top */
.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

/* padding bottom */
.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

/* padding right */
.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

/* padding left */
.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

/* margin */

/* margin all */
.ma-0 {
  margin: 0 !important;
}

.ma-1 {
  margin: 4px !important;
}

.ma-2 {
  margin: 8px !important;
}

.ma-3 {
  margin: 12px !important;
}

.ma-4 {
  margin: 16px !important;
}

.ma-5 {
  margin: 20px !important;
}

.ma-50 {
  margin: 50px !important;
}

.ma-100 {
  margin: 100px !important;
}

/* margin x */
.mx-1 {
  margin: 0 4px !important;
}

.mx-2 {
  margin: 0 8px !important;
}

.mx-3 {
  margin: 0 12px !important;
}

.mx-4 {
  margin: 0 16px !important;
}

.mx-5 {
  margin: 0 20px !important;
}

.mx-50 {
  margin: 0 50px !important;
}

.mx-100 {
  margin: 0 100px !important;
}

/* margin y */
.my-1 {
  margin: 4px 0 !important;
}

.my-2 {
  margin: 8px 0 !important;
}

.my-3 {
  margin: 12px 0 !important;
}

.my-4 {
  margin: 16px 0 !important;
}

.my-5 {
  margin: 20px 0 !important;
}

.my-50 {
  margin: 50px 0 !important;
}

.my-100 {
  margin: 100px 0 !important;
}

/* margin top */
.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

/* margin bottom */
.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

/* margin right */
.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

/* margin left */
.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

/* FLEX */

.d-flex {
  display: flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.flexy {
  flex: 1;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.cursor_pointer {
  cursor: pointer;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.cursor_text {
  cursor: text;
}

/* font & typography */
.xsmall_txt {
  font-size: x-small;
}

.small_txt {
  font-size: small;
}

.px11_txt {
  font-size: 11px;
}

.px12_txt {
  font-size: small;
}

.medium_txt {
  font-size: medium;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.white-space-pre {
  white-space: pre;
}

.black-text {
  color: #000 !important;
}

.white-text {
  color: #fff !important;
}

.red-text {
  color: #f44336 !important;
}

.pink-text {
  color: #e91e63 !important;
}

.purple-text {
  color: #9c27b0 !important;
}

.deep-purple-text {
  color: #673ab7 !important;
}

.indigo-text {
  color: #3f51b5 !important;
}

.blue-text {
  color: #2196f3 !important;
}

.cyan-text {
  color: #00bcd4 !important;
}

.teal-text {
  color: #009688 !important;
}

.green-text {
  color: #4caf50 !important;
}

.light-green-text {
  color: #8bc34a !important;
}

.lime-text {
  color: #cddc39 !important;
}

.yellow-text {
  color: #ffeb3b !important;
}

.amber-text {
  color: #ffc107 !important;
}

.orange-text {
  color: #ff9800 !important;
}

.deep-orange-text {
  color: #ff5722 !important;
}

.brown-text {
  color: #795548 !important;
}

.grey-text {
  color: #9e9e9e !important;
}

.primary-light-bg:hover {
  background: #fbf4fe;
}

.white-bg {
  background: #fff !important;
}

.red-bg {
  background: #f44336 !important;
}

.pink-bg {
  background: #e91e63 !important;
}

.purple-bg {
  background: #9c27b0 !important;
}

.deep-purple-bg {
  background: #673ab7 !important;
}

.indigo-bg {
  background: #3f51b5 !important;
}

.blue-bg {
  background: #2196f3 !important;
}

.cyan-bg {
  background: #00bcd4 !important;
}

.teal-bg {
  background: #009688 !important;
}

.green-bg {
  background: #4caf50 !important;
}

.light-green-bg {
  background: #8bc34a !important;
}

.lime-bg {
  background: #cddc39 !important;
}

.yellow-bg {
  background: #ffeb3b !important;
}

.amber-bg {
  background: #ffc107 !important;
}

.orange-bg {
  background: #ff9800 !important;
}

.deep-orange-bg {
  background: #ff5722 !important;
}

.brown-bg {
  background: #795548 !important;
}

.grey-bg {
  background: #9e9e9e !important;
}

.rounded-icon {
  width: 28px;
  height: 28px;
  border-radius: 14px;
}

.border-solid {
  border: 2px solid black;
}

.border-solid-light {
  border: 2px solid #d9d9d9;
}

.box-date {
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  margin: 2px;
}

/* Position */
.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed !important;
}

.sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-1 {
  top: 1px !important;
}

.top-2 {
  top: 2px !important;
}

.top-3 {
  top: 3px !important;
}

.top-4 {
  top: 4px !important;
}

.top-5 {
  top: 5px !important;
}

.top-6 {
  top: 6px !important;
}

.top-7 {
  top: 7px !important;
}

.top-8 {
  top: 8px !important;
}

.top-9 {
  top: 9px !important;
}

.top-10 {
  top: 10px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-1 {
  bottom: 1px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.bottom-3 {
  bottom: 3px !important;
}

.bottom-4 {
  bottom: 4px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.bottom-6 {
  bottom: 6px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.bottom-8 {
  bottom: 8px !important;
}

.bottom-9 {
  bottom: 9px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.left-0 {
  left: 0 !important;
}

.left-1 {
  left: 1px !important;
}

.left-2 {
  left: 2px !important;
}

.left-3 {
  left: 3px !important;
}

.left-4 {
  left: 4px !important;
}

.left-5 {
  left: 5px !important;
}

.left-6 {
  left: 6px !important;
}

.left-7 {
  left: 7px !important;
}

.left-8 {
  left: 8px !important;
}

.left-9 {
  left: 9px !important;
}

.left-10 {
  left: 10px !important;
}

.right-0 {
  right: 0 !important;
}

.right-1 {
  right: 1px !important;
}

.right-2 {
  right: 2px !important;
}

.right-3 {
  right: 3px !important;
}

.right-4 {
  right: 4px !important;
}

.right-5 {
  right: 5px !important;
}

.right-6 {
  right: 6px !important;
}

.right-7 {
  right: 7px !important;
}

.right-8 {
  right: 8px !important;
}

.right-9 {
  right: 9px !important;
}

.right-10 {
  right: 10px !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

/* Shadow */
.shadow-none {
  box-shadow: 0px 0px 0px 0px !important;
}

.shadow-sm {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Leading */
.leading-none {
  line-height: 1;
}

.leading-3 {
  line-height: 0.75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

/* Table */
.table-responsive {
  overflow-x: auto;
}

.table table {
  border-collapse: collapse;
  font-size: 0.8em;
}

.table thead tr {
  text-align: center;
}

.table th,
.table td {
  padding: 5px 10px;
  border: 1px solid #d1d1d1;
}

/* Cheked */
.checked-style {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: white;
  position: relative;
}

.checked-style::before {
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  top: -1.5px;
  width: 3px;
  height: 8px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: white;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.checked-style:checked::before {
  display: block;
}

.fileInputHidden {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}

.radio-style {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}

.hide-scrollable {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollable::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
